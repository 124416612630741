import { GetStaticProps } from 'next';
import { useEffect, useMemo, useState } from 'react';
import Head from 'next/head';
import { Box } from '@mui/material';

// Store
import { useAppDispatch } from '../redux-toolkit/store/hooks';
import { ssrWrapper } from '~/redux-toolkit/store/store';
import {
  getHomePageOurServices,
  getHomePageBannerGallery,
  getHomePageStaticBanners,
  getSearchByNeeds,
  getSearchPlansSearch,
  getHomePageQuickSearch,
  getHomePageContent,
} from '~/redux-toolkit/thunks/homePageThunks';
import { getMarketingData } from '~/redux-toolkit/thunks/marketingThunks';
import { getBlogContent } from '~/redux-toolkit/thunks/blogThunks';

// Hooks & Utils
import { useIsDisplayMarketingPopup } from '~/hooks/useIsDisplayMarketingPopup';
import { EActionEvent, ECategoryEvent, useEvents } from '~/hooks/useEvents';

// Components
import Hero from '~/components/HeroV2';

import Blogs from '~/components/Blogs';
import SearchPlans from '~/components/SearchPlans';
import OurServices from '~/components/OurServices';
const BannerGallery = dynamic(() => import('~/components/BannerGallery'), {
  ssr: false,
});
import ContactUsBanner from '~/components/ContactUsBanner';
import SearchByNeeds from '~/components/SearchByNeeds/SearchByNeeds';
const MarketingPopup = dynamic(
  () => import('~/components/MarketingPopup/MarketingPopup'),
  {
    ssr: false,
  },
);

// Types & Data
import { OurServicesType } from '~/data/types/ourServices/ourServices.types';
import { META_TEXT } from '~/data/seoMetaText';
import { BANNER_LOCATION, SEARCH_LOCATION } from '~/data/consts';
import {
  BannerCardType,
  SearchByNeedResponseType,
} from '~/data/types/bannerCard/bannerCard.types';
import { SearchPlanContentType } from '~/data/types/searchPlanType';
import { homePageContentType } from '~/data/types/homePageContentType';
import RESOURCES from '~/resources';
import { getIHomePageData } from '~/redux-toolkit/thunks/homePageContentThunks';
import StaticBannersCarousel from '~/components/StaticBannersCarousel';
import { IHomePageData } from '~/data/types/homePageContent/homePage.types';
import { useRouter } from 'next/router';
import { getCampaignMappedBanners } from '~/redux-toolkit/utils/bannersUtils';
import { filterArrayByUtmCampaign } from '~/utils/utmStorage';
import dynamic from 'next/dynamic';

type HomeProps = {
  galleryBanners: BannerCardType[];
  ourServices: OurServicesType;
  staticBanners: BannerCardType[];
  searchByNeed: SearchByNeedResponseType;
  searchPlans: SearchPlanContentType;
  content: homePageContentType;
  homePageContent: IHomePageData;
};

export default function Home({
  searchByNeed,
  galleryBanners,
  ourServices,
  searchPlans,
  content,
  homePageContent,
}: HomeProps) {
  // Hooks
  const events = useEvents();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [isEventLoadPage, setIsEventLoadPage] = useState<boolean>(false);
  const shouldDisplayMarketingPopup = useIsDisplayMarketingPopup();
  const utmCampaign = router.query?.utmCampaign as string;

  // Update Store
  useEffect(() => {
    dispatch(getMarketingData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (shouldDisplayMarketingPopup) {
      timer = setTimeout(() => {
        setShowPopup(true);
      }, 5000);
    } else {
      setShowPopup(false);
    }

    return () => clearTimeout(timer);
  }, [shouldDisplayMarketingPopup]);

  useEffect(() => {
    if (!isEventLoadPage) {
      events.sendEvent({
        category: ECategoryEvent.HOMEPAGE,
        action: EActionEvent.HOMEPAGE_PAGE_LOAD,
      });
      setIsEventLoadPage(true);
    }
  }, [events, isEventLoadPage]);

  const parsedGalleryBanners = useMemo(() => {
    if (!utmCampaign) {
      return galleryBanners;
    }

    const bannerGallery = filterArrayByUtmCampaign(
      homePageContent?.bannerGallery,
      'campaignBannerGallery',
      utmCampaign,
    );

    if (!bannerGallery) {
      return galleryBanners;
    }

    return getCampaignMappedBanners(bannerGallery?.[0].bannerCards);
  }, [utmCampaign, galleryBanners, homePageContent?.bannerGallery]);

  const bannerCarousels = useMemo(() => {
    if (!utmCampaign) {
      return {
        ...homePageContent?.bannerCarousels,
        campaignMarketingBannerCarousels: null,
      };
    }

    return {
      ...homePageContent?.bannerCarousels,
      campaignMarketingBannerCarousels: filterArrayByUtmCampaign(
        homePageContent?.bannerCarousels,
        'campaignMarketingBannerCarousels',
        utmCampaign,
      ),
    };
  }, [utmCampaign, homePageContent?.bannerCarousels]);

  return (
    <>
      <Head>
        <title>{META_TEXT.HOMEPAGE_TITLE}</title>
        <meta name="description" content={META_TEXT.HOMEPAGE_DESCRIPTION} />
        <link rel="canonical" href={process.env.NEXT_PUBLIC_BASE_URL} />
      </Head>
      <Box>
        <Hero
          title={content?.title}
          subtitle={content?.subtitle}
          bottomButtonUrl={
            'https://rental.freesbe.com?utm_source=freesbe&utm_medium=referral'
          }
        />
        {galleryBanners.length > 0 && (
          <BannerGallery
            banners={parsedGalleryBanners}
            location={BANNER_LOCATION.homePageGallery}
            title={RESOURCES.HOME_PAGE_BANNERS_GALLERY}
          />
        )}
        <SearchPlans searchPlans={searchPlans} />
        <StaticBannersCarousel
          bannerCarousels={bannerCarousels}
          location={BANNER_LOCATION.homePageStaticBanner}
        />
        {searchByNeed && (
          <SearchByNeeds
            data={searchByNeed}
            event={{
              action: EActionEvent.SEARCH_FLOW_SEARCH_TYPE,
              category: ECategoryEvent.HOMEPAGE,
              data: {
                searchType: RESOURCES.SEARCH_FLOW_GENERAL,
                location: SEARCH_LOCATION.homePage,
              },
            }}
          />
        )}
        <OurServices services={ourServices} />
        <ContactUsBanner />
        <Blogs />
        {showPopup && shouldDisplayMarketingPopup && (
          <MarketingPopup open={shouldDisplayMarketingPopup} />
        )}
      </Box>
    </>
  );
}

export const getStaticProps: GetStaticProps = ssrWrapper.getStaticProps(
  (store) => async (_context) => {
    // get homePage content
    await store.dispatch(getHomePageBannerGallery());
    await store.dispatch(getHomePageOurServices());
    await store.dispatch(getBlogContent());
    await store.dispatch(getHomePageStaticBanners());
    await store.dispatch(getSearchByNeeds());
    await store.dispatch(getSearchPlansSearch());
    await store.dispatch(getHomePageQuickSearch());
    await store.dispatch(getHomePageContent());
    await store.dispatch(getIHomePageData());

    const {
      homePage: {
        galleryBanners,
        staticBanners,
        ourServices,
        searchByNeed,
        searchPlans,
        content,
      },
    } = store.getState();

    const homePageContent = store.getState().homePageContent.homePage;
    return {
      props: {
        galleryBanners,
        staticBanners,
        ourServices,
        searchByNeed,
        searchPlans,
        content,
        homePageContent,
      },
      revalidate: 300,
    };
  },
);
